/// <reference path="../../node_modules/@types/jquery-validation-unobtrusive/index.d.ts" />
/// <reference path="../../node_modules/@types/google.analytics/index.d.ts" />
/// <reference path="../../node_modules/@types/dw-bxslider-4/index.d.ts" />
/// <reference path="dbs.styleswitch.ts" />
class EventTracking
{
    private static getParameterByName(url: string, name: string)
    {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");

        const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
        const results = regex.exec(url);

        return results === null
                   ? ""
                   : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    static setupEventTracking()
    {
    }
}

if (typeof ($.validator) !== "undefined")
{
    $.validator.setDefaults({
        ignore: ""
    });

    const setValidationValues = (options: any, ruleName: string, value: any) =>
    {
        options.rules[ruleName] = value;
        if (options.message)
        {
            options.messages[ruleName] = options.message;
        }
    };

    $.validator.unobtrusive.adapters.add("mustbetrue", options =>
    {
        setValidationValues(options, "mustbetrue", true);
    });

    $.validator.addMethod("mustbetrue", function(value, element, param)
    {
        if (!this.depend(param, element))
        {
            return false;
        }

        return (element as HTMLInputElement).checked;
    });
}

$(() =>
{
    EventTracking.setupEventTracking();
});